<template>
  <div v-show="false">
    <check-form
      v-for="(item, index) in checkPointList"
      :key="`check_${index}`"
      :ref="`checkForm_${index}`"
      :order-id="orderId"
      :article-id="articleId"
      :visible="true"
    />
    <custom-check-form
      v-for="(item, index) in customCheckPointList"
      :key="`custom_check_${index}`"
      :ref="`customCheckForm_${index}`"
      :order-id="orderId"
      :article-id="articleId"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import checkForm from "@/components/V2/Check/CheckForm.vue";
import customCheckForm from "@/components/V2/Check/CustomCheckForm.vue";
import {cloneDeep} from "lodash";
import {imgStrToList} from "@/utils/util";

export default {
  name: "hiddenValidator",
  components: {
    checkForm,
    customCheckForm
  },
  props: {
    checkPointList: {
      type: Array,
      default() {
        return []
      }
    },
    customCheckPointList: {
      type: Array,
      default() {
        return []
      }
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['taskInfo'])
  },
  methods: {
    init() {
      this.checkPointList.forEach((item, index) => {
        const checkFormRef = this.$refs[`checkForm_${index}`][0]
        const checkItem = cloneDeep(item)
        checkFormRef.setForm(checkItem)
      })
      this.customCheckPointList.forEach((item, index) => {
        const customCheckFormRef = this.$refs[`customCheckForm_${index}`][0]
        const checkItem = cloneDeep(item)
        checkItem.pictures = imgStrToList(checkItem.pictures)
        customCheckFormRef.setForm(checkItem)
      })
    },
    validateDefect( item, isCustom) {
      if (item.conclusion === 'FAILED') {
        const taskInfo = cloneDeep(this.taskInfo)
        const order = taskInfo.orderList.find(item => item.id === this.orderId)
        const article = order.articleNoList.find(item => item.id === this.articleId)
        const defectList = article.inspectionList.defectList
        let index = -1
        console.log(item, defectList.map(item => item.checkPointId).join(','))
        if (isCustom) {
          index = defectList.findIndex(i => i.checkPointId === item.id)
        } else {
          index = defectList.findIndex(i => i.checkPointId === item.checkPointsId)
        }
        if (!~index) {
          return false
        }
      }
      return true
    },
    validate(func) {
      setTimeout(() => {
        let inValidDefectItem = null
        let tipName = ''
        let requiredResultName = ''
        this.checkPointList.forEach((item, index) => {
          if(!this.validateDefect(item, false)) {
            inValidDefectItem = item.pointsName
            return
          }
          const checkFormRef = this.$refs[`checkForm_${index}`][0]
          checkFormRef.validate(async (valid, func) => {
            if (!valid) {
              tipName = item.pointsName
            }
            if(!item.conclusion) {
              tipName = item.pointsName
            }
          })


          const dataValue = item.dataValue ? JSON.parse(item.dataValue) : {}
          const formOption = item.form ? JSON.parse(item.form) : {}
          formOption.column.forEach(col => {
            if(col.dataProp === 'checkResult' && !dataValue[col.prop]) {
              // requiredResultName = item.label + '-' + item.pointsName + '检查结果'
              requiredResultName = item.pointsName + '检查结果'
            }
            if(col.dataProp === 'isRead' && !dataValue[col.prop]) {
              // requiredResultName = item.label + '-' + item.pointsName + '已读未读'
              requiredResultName = item.pointsName + '已读未读'
            }
          })
        })
        this.customCheckPointList.forEach((item, index) => {
          if(!this.validateDefect(item, true)) {
            inValidDefectItem = item.inspectionItemName + '-' + item.checkPointName
            return
          }
          const customCheckFormRef = this.$refs[`customCheckForm_${index}`][0]
          if (!customCheckFormRef.validate()) {
            tipName = item.inspectionItemName + '-' + item.checkPointName
          }
          if(!item.conclusion) {
            requiredResultName = item.inspectionItemName + '-' + item.checkPointName + '检查结果'
          }
        })
        setTimeout(() => {
          if(inValidDefectItem) {
            this.Toast.fail(`请添加缺陷佐证${inValidDefectItem}不合格`)
          } else if(tipName) {
            this.Toast.fail(`${tipName}校验未通过`)
          } else if(requiredResultName) {
            this.Toast.fail(`${requiredResultName}未填写`)
          }else func()
        })
      }, 0)
    }
  },
}
</script>

<style lang="less" scoped>

</style>