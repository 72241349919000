<template>
  <div class="check-detail">
    <div class="content">
      <Card class="title-card" :show-divider="false">
        <!--        <div class="space-between sample-title-box" slot="title">-->
        <!--          <div>点数抽样</div>-->
        <!--          <div v-if="!disabled" class="edit-btn" @click="handleEditSample">编辑</div>-->
        <!--        </div>-->
        <div class="title-box">
          <div>
            <div style="font-size: 16px; margin-bottom: 10px">{{ articleInfo ? articleInfo.articleNo : '' }}</div>
            <div style="color: #797C80; font-size: 12px">{{ articleInfo ? articleInfo.articleNoDesc : '' }}</div>
          </div>
          <div v-if="!disabled" class="count-sample-btn" @click="handleEditSample">点数抽样</div>
        </div>
        <div class="desc-item">订单号：{{ orderNum }}</div>
        <div class="desc-item">
          订单/完成/抽检数量：
          {{
            articleInfo.applicationQuantity || '-'
          }}/{{
            articleInfo.skipReason ? `未点数：${articleInfo.skipReason}` : qualityNumber(articleInfo.completedQuantity)
          }}/{{ qualityNumber(articleInfo.samplingQuantity)}}
        </div>
        <div class="desc-item">
          AQL：CRI {{ articleInfo.aqlCri }}, MAJ {{ articleInfo.aqlMaj }}, MIN {{ articleInfo.aqlMin }}
        </div>
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">订单号：</div>-->
        <!--          <div class="value">{{ orderNum }}</div>-->
        <!--        </div>-->
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">验货数量</div>-->
        <!--          <div v-if="articleInfo.skipReason" class="value">未点数：{{ articleInfo.skipReason }}</div>-->
        <!--          <div v-else class="value">{{ articleInfo.completedQuantity || '/' }}</div>-->
        <!--        </div>-->
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">订单数量</div>-->
        <!--          <div class="value">{{ articleInfo.applicationQuantity }}</div>-->
        <!--        </div>-->
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">AQL</div>-->
        <!--          <div class="value">CRI {{ articleInfo.aqlCri }}, MAJ {{ articleInfo.aqlMaj }}, MIN-->
        <!--            {{ articleInfo.aqlMin }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">完成数量</div>-->
        <!--          <div v-if="articleInfo.skipReason" class="value">未点数：{{ articleInfo.skipReason }}</div>-->
        <!--          <div v-else class="value">{{ articleInfo.completedQuantity || '/' }}</div>-->
        <!--&lt;!&ndash;          <div class="value">{{ articleInfo.completedQuantity }}{{ completePercent }}</div>&ndash;&gt;-->
        <!--        </div>-->
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">抽检数量</div>-->
        <!--          <div class="value">{{ articleInfo.samplingQuantity }}（{{ articleInfo.samplingLevel }}）</div>-->
        <!--        </div>-->
      </Card>
      <inspection-pictures-card
        v-model="inspectionPictures"
        :deletable="!disabled"
        :show-upload="!disabled"
        class="pictures-card"
        @input="onInspectionPicturesChange"
      />
      <check-item-card
        ref="checkItemCard"
        :order-id="orderId"
        :article-id="articleId"
        :article-info="articleInfo"
        :readonly="disabled"
        :is-checkup-btn-default-disable="$route.query.checkFrom !== 'result'"
        @handleCheckPopupOk="handleCheckPopupOk"
        @onCustomCheckItemDel="handleCheckPopupOk"
      ></check-item-card>
      <hidden-validator
        ref="hiddenValidator"
        :order-id="orderId"
        :article-id="articleId"
        :check-point-list="checkPointList"
        :custom-check-point-list="customCheckPointList"
      />
    </div>
    <div class="padding-for-bottom"></div>
    <div v-if="!disabled" class="fixed-btn">
      <div class="btn-box space-between vertical-center top-btn-box">
        <van-button
          type="primary"
          color="#055C9D"
          class="step-btn"
          plain
          @click="toggleDefectPopup"
        >
          缺陷
        </van-button>
        <van-button
          type="primary"
          color="#055C9D"
          class="step-btn"
          plain
          @click="toggleRemarkPopup"
        >
          备注
        </van-button>
<!--        <van-button-->
<!--          type="primary"-->
<!--          class="step-btn"-->
<!--          :icon="!disabled ? 'plus' : ''"-->
<!--          plain-->
<!--          @click="togglePopup"-->
<!--        >-->
<!--          检查点-->
<!--        </van-button>-->
      </div>
      <div class="btn-box space-between vertical-center">
        <van-button
          type="primary"
          size="large"
          :loading="btnLoading"
          :disabled="btnLoading || disabled"
          loading-text="提交中"
          color="#055C9D"
          @click="handleSubmit"
        >
          提交验货报告
        </van-button>
      </div>
    </div>
    <defect-list-popup
      :show="isShowDefectListPop"
      :order-id="orderId"
      :article-id="articleId"
      :readonly="disabled"
      @on-close="toggleDefectPopup"
    />
    <remark-list-popup
      :show="isShowRemarkListPop"
      :order-id="orderId"
      :article-id="articleId"
      :readonly="disabled"
      @on-close="toggleRemarkPopup"
    />
    <new-check-point-popup
      :show="showPopup"
      :order-id="orderId"
      :article-id="articleId"
      @on-close="togglePopup"
      @on-ok="handlePopupOk"
    />
    <check-result-dialog
      ref="checkResultDialog"
      @on-ok="onCheckResultOk"
    />
  </div>
</template>

<script>
import {InbounceMixin} from '@/mixins/InbounceMixin';
import {
  SAMPLING_LEVEL_DICT,
  CHECK_JUDGE_DICT,
  ARTICLE_NO_WAITING_CERTAIN,
  ARTICLE_CONCLUSION_AGREE,
  ARTICLE_CONCLUSION_NOT,
  taskStatusObj, ARTICLE_NO_COMPLETED, SUPPLIER_CONCLUSION_AGREE
} from '@/constants';
import {getRandomId} from '@/utils/util';
import Card from '@/components/Common/Card';
import ImageUploader from '@/components/Common/ImageUploader';
import DefectListPopup from '@/components/V2/Defect/DefectListPopup';
import RemarkListPopup from '@/components/V2/Remark/RemarkListPopup';
import NewCheckPointPopup from '@/components/V2/Check/NewCheckPointPopup';
import CheckPopup from '@/components/V2/Check/CheckPopup';
import CheckResultDialog from "@/components/V2/Check/CheckResultDialog.vue";
import InspectionPicturesCard from "@/components/Common/InspectionPicturesCard.vue";
import hiddenValidator from "@/components/V2/Check/hiddenValidator.vue";
import CheckItemCard from "@/components/Common/CheckItemCard.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";
import moment from "moment/moment";
import {isNative} from "@/config";
import {imgStrToList} from "@/utils/util";

export default {
  name: "check",
  mixins: [InbounceMixin],
  components: {
    Card,
    ImageUploader,
    DefectListPopup,
    RemarkListPopup,
    NewCheckPointPopup,
    CheckPopup,
    CheckResultDialog,
    hiddenValidator,
    InspectionPicturesCard,
    CheckItemCard
  },
  data() {
    return {
      orderId: '',
      articleId: '',
      articleInfo: {},
      checkPointList: [],
      customCheckPointList: [],
      inspectionPictures: [],
      conclusion: '',
      conclusionReason: '',
      isShowDefectListPop: false,
      isShowRemarkListPop: false,
      showPopup: false,
      btnLoading: false,
      SAMPLING_LEVEL_DICT,
      CHECK_JUDGE_DICT,
      validatorCheckPointList: [],
      validatorCustomCheckPointList: [],
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    orderNum() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      return order?.orderNum || ''
    },
    completePercent() {
      const completedQuantity = parseInt(this.articleInfo?.completedQuantity || 0)
      const applicationQuantity = parseInt(this.articleInfo?.applicationQuantity || 0)
      if (applicationQuantity !== 0) {
        const percent = completedQuantity * 100 / applicationQuantity
        return `（${parseInt(percent)}%）`
      }
      return ''
    },
    disabled() {
      return this.taskInfo?.status === taskStatusObj.TASK_STATUS_APPLY_CANCEL || this.$route.query.disabled === '1'
    },
    coverImg() {
      if (this.articleInfo?.productImages) {
        const productImageList = this.articleInfo.productImages.split(',')
        if (productImageList.length) {
          return productImageList[0]
        }
      }
      return require('@/assets/img/default-img.png')
    },
    qualityNumber(){
      return function (val) {
        if(!val && val !== 0) {
          return '-'
        } else {
          return val
        }
      }
    },
  },
  async created() {
    if (this.$route.query.disabled === '1') {
      document.title = '查看检验清单'
    } else {
      document.title = '验货中'
    }
    this.SET_TASK_ID(this.$route.query.taskId)
    this.orderId = this.$route.query.orderId
    this.articleId = this.$route.query.articleId.replace(/\?time=(\d+)/g, '')  // todo: 本地调试用
    await this.getTaskInfo()
    this.getArticleInfo()
    this.getCheckItemList()
  },
  methods: {
    ...mapMutations(['SET_TASK_ID']),
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleCoverClick() {
      let productImageList = []
      if (this.articleInfo?.productImages) {
        productImageList = this.articleInfo.productImages.split(',')
        if (productImageList.length) {
          this.ImagePreview(productImageList)
        }
      }
    },
    async onInspectionPicturesChange() {
      const taskInfo = await this.getSubmitData()
      const res = await this.saveTaskInfo(taskInfo)
      if (res.success) {
        await this.getTaskInfo()
        this.getArticleInfo()
        this.getCheckItemList()
      }
    },
    handleEditSample() {
      this.$router.push({
        path: '/countSample',
        query: {
          ...this.$route.query,
          from: 'check'
        },
      })
    },
    toggleDefectPopup() {
      this.isShowDefectListPop = !this.isShowDefectListPop;
    },
    toggleRemarkPopup() {
      this.isShowRemarkListPop = !this.isShowRemarkListPop;
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    async handlePopupOk() {
      await this.getTaskInfo()
      this.getArticleInfo()
      this.getCheckItemList()
      this.togglePopup()
    },
    handleSubmit() {
      this.$refs.hiddenValidator.init()
      this.$refs.hiddenValidator.validate(() => {
        this.$refs.checkResultDialog.open()
      })
    },
    async onCheckResultOk(form) {
      this.conclusion = form.result
      this.conclusionReason = form.reason
      const articleStatus = form.result === ARTICLE_CONCLUSION_AGREE ? ARTICLE_NO_COMPLETED : ARTICLE_NO_WAITING_CERTAIN
      // const articleStatus = ARTICLE_NO_COMPLETED
      const taskInfo = await this.getSubmitData(articleStatus)
      const res = await this.saveTaskInfo(taskInfo)
      this.conclusion = ''
      this.conclusionReason = ''
      if (res.success) {
        this.$router.replace({
          path: '/orderList',
          query: {
            ...this.$route.query
          },
        })
      }
    },
    async getSubmitData(articleStatus) {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionPictures = this.inspectionPictures.map(item => item.url).join(',')
      article.conclusion = this.conclusion
      article.conclusionReason = this.conclusionReason
      article.supplierConfirm = this.conclusion === ARTICLE_CONCLUSION_AGREE ? SUPPLIER_CONCLUSION_AGREE : ''
      if (this.conclusion) {
        article.finishTime = moment().format('YYYY-MM-DD HH:mm:ss')
        article.conclusionTime = moment().format('YYYY-MM-DD HH:mm:ss')
      }
      let user = 'H5'
      let userId = ''
      if (isNative) {
        try {
          const res = await window.nativeApp.getUserInfo()
          if (res.success) {
            const result = JSON.parse(res.result)
            userId = result.userId
            user = result.user
          }
        } catch (e) {
          console.log('获取用户信息出错：')
          console.error(e)
        }
      }
      if (articleStatus) {
        article.status = articleStatus
      }
      if ([ARTICLE_NO_WAITING_CERTAIN, ARTICLE_NO_COMPLETED].includes(articleStatus)) {
        article.conclusionList.push({
          id: getRandomId(),
          role: "INSPECTOR",
          user,
          userId,
          submitTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          conclusion: this.conclusion
        })
      }
      return taskInfo
    },
    getArticleInfo() {
      const orderList = this.taskInfo.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      const articleNoList = order.articleNoList || []
      this.articleInfo = articleNoList.find(item => item.id === this.articleId)
      this.inspectionPictures = imgStrToList(this.articleInfo.inspectionPictures)
    },
    async handleCheckPopupOk() {
      await this.getTaskInfo()
      this.getArticleInfo()
      this.getCheckItemList()
    },
    getCheckItemList() {
      const checkPointList = cloneDeep(this.articleInfo.inspectionList.checkPointList)
      this.checkPointList = checkPointList
      // let spliceIndexList = []
      // checkPointList.forEach((item, index) => {
      //   const root = this.checkPointList.find(root => root.inspectionItemId === item.inspectionItemId)
      //   if (root) {
      //     if (!root.children?.length) {
      //       root.children = [item]
      //     } else {
      //       root.children.push(item)
      //       spliceIndexList.push(index)
      //     }
      //   } else {
      //     this.checkPointList.push({
      //         inspectionItemId: item.inspectionItemId,
      //         inspectionItem: item.inspectionItem,
      //         children: [item]
      //       }
      //     )
      //   }
      // })
      // this.checkPointList = this.checkPointList.filter((item, index) => !spliceIndexList.includes(index))

      const customCheckPointList = cloneDeep(this.articleInfo.inspectionList.customCheckPointList)
      this.customCheckPointList = customCheckPointList
      // let newSpliceIndexList = []
      // customCheckPointList.forEach((item, index) => {
      //   const root = this.customCheckPointList.find(root => root.inspectionItemName === item.inspectionItemName)
      //   if (root) {
      //     if (!root.children?.length) {
      //       root.children = [item]
      //     } else {
      //       root.children.push(item)
      //       newSpliceIndexList.push(index)
      //     }
      //   } else {
      //     this.customCheckPointList.push({
      //         inspectionItemName: item.inspectionItemName,
      //         children: [item]
      //       }
      //     )
      //   }
      // })
      // this.customCheckPointList = this.customCheckPointList.filter((item, index) => !newSpliceIndexList.includes(index))

      this.$refs.checkItemCard.init(this.checkPointList, this.customCheckPointList)
    },
  },
}
</script>

<style scoped lang="less">
@import "check";
</style>
